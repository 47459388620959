import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { Form, Button, message, Input } from "antd";
import api from "../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { navigate } from "gatsby";
import { AuthContext } from "../../context/GlobalContextProvider";

export default function Contato() {
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [adress, setAdress] = useState();
  const context = useContext(AuthContext);
  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    setLoading(true);
    const { data } = await api.get("/contato");
    setPage(data);
    setLoading(false);
  };
  const handleSubmit = async (values) => {
    setLoadingSave(true);
    var data = new FormData();
    data.append(
      "data",
      JSON.stringify({
        endereco: values.endereco,
        telefone: values.telefone,
        email: values.email,
      })
    );

    await api({
      method: "PUT",
      url: "/contato",
      headers: {
        Authorization: `Bearer ${context?.token}`,
      },
      data,
    }).then((value) => {
      if (value) {
        message.success("Página salva com sucesso");
        navigate(`/contato`);
      }
    });
    setLoadingSave(false);
  };

  return (
    <Layout loading={loading}>
      <Form onFinish={handleSubmit}>
        <SEO
          keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
          title="Contato"
        />
        <h1 className="text-center">Contato</h1>
        <div className="lg:flex py-24 md:mx-10">
          <div className="mx-2 md:mx-4 w-full lg:w-3/5">
            <h2 className="mb-6">Como chegar</h2>
            <Form.Item
              initialValue={page?.endereco}
              rules={[{ required: true, message: "Digite um endereço" }]}
              name="endereco"
            >
              <Input
                placeholder="Digite o endereço"
                className="mb-10"
                value={adress}
                onChange={(e) => setAdress(e.target.value)}
              />
            </Form.Item>
            {adress && (
              <iframe
                className=" w-full border-solid border-2 border-blue-500"
                title="SoftinLocationMap"
                height="360"
                frameBorder="0"
                src={`https://maps.google.com/maps?q=${encodeURI(
                  adress
                )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                allowFullScreen=""
              />
            )}
          </div>
          <div className="mx-2 md:mx-8 mt-5 lg:mt-0 lg:w-1/2 xl:w-2/4">
            <h2 className="mb-6">Base operacional</h2>
            <div className="flex mb-2">
              {" "}
              <Form.Item
                initialValue={page?.endereco}
                rules={[{ required: true, message: "Digite um endereço" }]}
                name="endereco"
              >
                <Input
                  placeholder="Digite o endereço"
                  className="mb-10"
                  value={adress}
                  onChange={(e) => setAdress(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="flex mb-2">
              <Form.Item initialValue={page?.telefone} name="telefone">
                <Input
                  placeholder="Digite o Telefone"
                  className="mb-10"
                  value={adress}
                  onChange={(e) => setAdress(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="flex mb-2">
              {" "}
              <Form.Item initialValue={page?.email} name="email">
                <Input
                  placeholder="Digite o e-mail"
                  className="mb-10"
                  value={adress}
                  onChange={(e) => setAdress(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item>
          <Button
            className="mt-10"
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
